import { useState } from 'react'
import {
	Flex,
	IconButton,
	Img
} from '@chakra-ui/react'
import logo from '../../../../logoOA W.svg'

import {
	FiMenu,
	FiHome,
	FiSliders,
	FiSettings,
	FiMonitor,
	FiUsers,
	FiPackage
} from 'react-icons/fi'
import NavItem from './NavItem'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
export default function Sidebar() {
	const [navSize, changeNavSize] = useState('large')
	const location = useLocation()
	const { user } = useSelector((state: RootState) => state.auth)

	return (
		<Flex
			pos="sticky"
			left="5"
			h="95vh"
			marginTop="2.5vh"
			mr='2em'
			boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
			borderRadius={navSize === 'small' ? '15px' : '30px'}
			w={navSize === 'small' ? '75px' : '200px'}
			flexDir="column"
			background='#191919'
			justifyContent="space-between"
		>
			<Flex
				p="5%"
				flexDir="column"
				w="100%"
				h='100%'
				alignItems={navSize === 'small' ? 'center' : 'flex-start'}
				as="nav"
			>

				<IconButton
					color='white'
					aria-label=''
					background="none"
					mt={5}
					_hover={{ background: 'none' }}
					icon={<FiMenu />}
					onClick={() => {
						if (navSize === 'small')
							changeNavSize('large')
						else
							changeNavSize('small')
					}}
				/>
				<Flex flexDirection='column' justifyContent='space-evenly' h='100%'>
					<NavItem navSize={navSize} icon={FiHome} title="Dashboard" active={location.pathname === '/dashboard'} route='/dashboard' />
					<NavItem navSize={navSize} icon={FiMonitor} title="Devices" active={location.pathname.startsWith('/dashboard/devices') } route='/dashboard/devices'/>
					{ user?.role === 'sat' || user?.role === 'admin' || user?.role === 'prod'?  
						<NavItem navSize={navSize} icon={FiUsers} title="Sat" active={location.pathname === '/dashboard/sat'}route='/dashboard/sat'/>
						: null }
					{ user?.role === 'admin'|| user?.role === 'prod' ?  
						<NavItem navSize={navSize} icon={FiPackage} title="Production" active={location.pathname.startsWith('/dashboard/production') } route='/dashboard/production'/>
						: null }
					{ user?.role === 'admin' ?  
						<NavItem navSize={navSize} icon={FiSliders} title="Admin" active={location.pathname === '/dashboard/admin'}route='/dashboard/admin'/>
						: null }
					<NavItem navSize={navSize} icon={FiSettings} title="Settings" active={location.pathname === '/dashboard/settings'}route='/dashboard/settings'/>
					
				</Flex>
                
			</Flex>

			<Flex
				p="5%"
				flexDir="column"
				w="100%"
				alignItems={navSize === 'small' ? 'center' : 'flex-start'}
				mb={4}
			>
                
			</Flex>
		</Flex>
	)
}