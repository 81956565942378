import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import { DrawerBody, DrawerContent, Drawer, useDisclosure, Box, Button, Flex, Divider, Img } from '@chakra-ui/react'
import { AiFillHome, AiFillSetting } from 'react-icons/ai'
import { CgSmartHomeWashMachine } from 'react-icons/cg'
import { RiAdminFill, RiCustomerServiceFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RootState } from '../../../../redux/store'
import { FiPackage } from 'react-icons/fi'

export default function DrawerBar() {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { user } = useSelector((state: RootState) => state.auth)

	const history = useHistory()
	return (
		<>
			<Box as='button' p={5} pl={0} onClick={onOpen} >
				<HamburgerIcon/>
			</Box>
			<Drawer size={'full'} placement='left'  onClose={onClose} isOpen={isOpen}>
				<DrawerContent>
					<Flex flexDirection='row'>
						<Button mt={5} mb={5} ml='auto' mr={5} variant='link' onClick={onClose}><CloseIcon/></Button>
					</Flex>
					<Divider />
					<DrawerBody>
						<Flex textAlign='center'  flexDirection='column' justifyContent='space-around'  height='80%'>
							<Button leftIcon={<AiFillHome/>} p={5}  variant='link' onClick={() => { onClose(); history.push('/dashboard') }}>
                Home
							</Button>
							<Divider/>
							<Button leftIcon={<CgSmartHomeWashMachine/>} p={5} variant='link' onClick={() => { onClose(); history.push('/dashboard/devices') }}>
                Devices
							</Button>
							<Divider/>
							
							{ user?.role === 'sat' || user?.role === 'admin' ?
								<>
									<Button leftIcon={<RiCustomerServiceFill/>} variant='link' p={5} onClick={() => {onClose(); history.push('/dashboard/sat') }}>
                 SAT
									</Button>
									<Divider/>
								</>
								: null}
							{  user?.role === 'prod' ||user?.role === 'admin' ?
								<>
									<Button leftIcon={<FiPackage/>} variant='link' p={5} onClick={() => {onClose(); history.push('/dashboard/production') }}>
                 Production
									</Button>
									<Divider/>
								</>
								: null}
							{ user?.role === 'admin' ?
								<>
									<Button leftIcon={<RiAdminFill/>} variant='link' p={5} onClick={() => {onClose(); history.push('/dashboard/admin') }}>
                 Admin
									</Button>
									<Divider/>
								</>
								: null}
							<Button leftIcon={<AiFillSetting/>} variant='link' p={5} onClick={() => {onClose(); history.push('/dashboard/settings') }}>
                Settings
							</Button>
							<Divider/>
						</Flex>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	)
}
