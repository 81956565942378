export const CREATE_CENTRIFUGE = 'https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/createCentrifuge'
//export const CREATE_CENTRIFUGE = 'https://us-central1-ortoalresa-digital-produccion.cloudfunctions.net/createCentrifuge'
//export const CREATE_CENTRIFUGE = 'http://localhost:5001/ortoalresa-digital-dev/us-central1/createCentrifuge'
export const DELETE_CENTRIFUGE= 'https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/deleteCentrifuge'
//export const DELETE_CENTRIFUGE= 'https://us-central1-ortoalresa-digital-produccion.cloudfunctions.net/deleteCentrifuge'
//export const DELETE_CENTRIFUGE= 'http://localhost:5001/ortoalresa-digital-dev/us-central1/deleteCentrifuge'
export const CREATE_USER='https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/createUser'
//export const CREATE_USER='https://us-central1-ortoalresa-digital-produccion.cloudfunctions.net/createUser'
//export const CREATE_USER='http://localhost:5001/ortoalresa-digital-dev/us-central1/createUser'
export const DELETE_USER = 'https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/deleteUser'
//export const DELETE_USER = 'https://us-central1-ortoalresa-digital-produccion.cloudfunctions.net/deleteUser'
//export const DELETE_USER = 'http://localhost:5001/ortoalresa-digital-dev/us-central1/deleteUser'
export const REGISTER_CENTRIFUGE = 'https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/registerCentrifuge'
//export const REGISTER_CENTRIFUGE = 'https://us-central1-ortoalresa-digital-produccion.cloudfunctions.net/registerCentrifuge'
//export const REGISTER_CENTRIFUGE = 'http://localhost:5001/ortoalresa-digital-dev/us-central1/registerCentrifuge'
export const GET_DATA = 'https://us-central1-ortoalresa-digital-dev.cloudfunctions.net/getCentrifugeData'