/* eslint-disable @typescript-eslint/no-explicit-any */
/* Panel principal que se muestra al autenticarse, el componente 'content' varía en función de la
	opción seleccionada en el Sidebar
*/

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Flex, Grid, useMediaQuery} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { RootState } from '../../redux/store'
import Home from './dashboard/content/Home'
import Devices from './dashboard/content/Devices'
import Sat from './dashboard/content/Sat'
import Admin from './dashboard/content/Admin'
import Settings from './dashboard/content/Settings'
import Topbar from './dashboard/topbar/Topbar'
import { useEffect } from 'react'
import { db } from '../../firebase/config'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { useState } from 'react'
import Sidebar from './dashboard/sidebar/Sidebar'
import Production from './Production'
import DeviceDetail from './dashboard/content/Devices/DeviceDetail'
import { getPfp } from '../../redux/actions/actions'

export default function Dashboard() {
    
	const {  needVerification } = useSelector((state: RootState) => state.auth)
	const {  user } = useSelector((state: RootState) => state.auth)
	const [ devices, setDevices ] = useState<any>([])
	const [isMobile] = useMediaQuery('(min-width: 48em)')
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(getPfp(user?.pfp))
		const q = query(collection(db, 'devices'), where('user_id', 'array-contains', user?.id))
		onSnapshot(q, (querySnapshot) => {
			setDevices(querySnapshot.docs.map(doc => doc.data()))
		})
	}, [])
	// firebase.firestore().collection('devices').where('user_id','array-contains', user?.id).onSnapshot(docSnapshot =>{
	// 	setDevices(docSnapshot.docs.map(doc => doc.data()))
	// }, err => {
	// 	console.log(`Encountered error: ${err}`)
	// })
	return (
		<Flex direction='row' background='#191919' >
			{isMobile ? <Sidebar/> : null}
			<Grid height='100vh' templateRows="4rem 1fr"  background='#191919' overflow='hidden'  gridTemplateAreas='"sidebar topbar" "sidebar content"'>
				<Flex background='white'  width='full' height={16} justifyContent='flex-start' pr={5} shadow='base' borderBottom='solid' borderBottomColor='rgb(246, 246, 246)' borderBottomWidth={1} gridArea="topbar">
					<Topbar/>
				</Flex>
				<Flex background='rgb(246, 246, 246)' gridArea="content" overflowY='auto' overflowX='hidden' justifyContent='center'>
					<Route path="/dashboard" render={() => <Home devices={devices} />} exact/>
					<Route path="/dashboard/devices" render={() => <Devices devices={devices}/>} exact/>
					<Route path="/dashboard/devices/:id" render={() => <DeviceDetail  />} exact/>
					<Route path="/dashboard/settings" render={() => <Settings />} exact/>
					<Route path="/dashboard/sat" render={() => <Sat/>} exact/>
					<Route path="/dashboard/production" render={() => <Production />} exact/>
					<Route path="/dashboard/production/:id" render={() => <DeviceDetail  />} exact/>
					<Route path="/dashboard/admin" render={() => <Admin /> } exact/>
                
				</Flex> 
				{ needVerification 
					?<Alert status="info" position='absolute' bottom={5} right={5} width='40vw' margin='auto' >
						<AlertIcon />
						<Box flex="1">
							<AlertTitle>Email verification</AlertTitle>
							<AlertDescription display="block">
                An email has been sent. Please verify your email to complete the registration 
               process.
							</AlertDescription>
						</Box>
					</Alert>
					: null }
			</Grid>
		</Flex>
	)
}
