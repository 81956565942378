/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AddIcon } from '@chakra-ui/icons'
import { DrawerBody, DrawerContent, Drawer, useDisclosure, Box, Button, DrawerCloseButton, DrawerHeader, Input, IconButton, FormControl, FormLabel, Select, ButtonGroup, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { SET_ERROR } from '../../../../../redux/types/types'
import { CREATE_CENTRIFUGE } from '../../../../../urlConfig'


export default function ConnectDeviceDrawer() {
	const dispatch = useDispatch()
	const firstFieldRef = React.useRef(null)
	const { isOpen, onOpen, onClose } = useDisclosure()

	const TextInput = React.forwardRef((props:any, ref) => {
		return (
			<FormControl>
				<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
				<Input ref={ref as any} name={props.id} {...props} onChange={props.handlechange} />
			</FormControl>
		)
	})
	const SelectInput = React.forwardRef((props:any, ref) => {
		return (
			<FormControl>
				<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
				<Select ref={ref as any} name={props.id} {...props} onChange={props.handlechange}>
					<option value="">- Choose model -</option>
					<option value="Minicen 24">Minicen 24</option>
					<option value="Microcen 22">Microcen 22</option>
					<option value="Microcen 22 R">Microcen 22 R</option>
				</Select>
			</FormControl>
		)
	})
	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 2000,
		timerProgressBar: false,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})
 
	const Form = ({ onCancel,dispatch }:any) => {

		const [formData, setFormData] = useState({
			sn:'', model:'',mac:''
		})
		const { sn, model, mac } = formData
		const handleSubmit = () =>{
			onCancel()
			try {
				fetch(CREATE_CENTRIFUGE,{
					method:'POST',
					mode: 'cors',
					body: JSON.stringify(formData)
				}).then(res => {
					res.status === 200 ? 
						Toast.fire({
							icon: 'success',
							title: 'User Created'
						}) : 
						Toast.fire({
							icon: 'error',
							title: 'An error has ocurred'
						})
				})
			} catch (error:any) {
				dispatch({
					type: SET_ERROR,
					payload: error.message
				})
			}
		}
		const handleChange = (e: { target: { name: any; value: any } }) =>{
			setFormData({
				...formData,
				[e.target.name] : e.target.value
			})
		}
		return (
			<Stack spacing={4}>
				<TextInput label="Serial Number"  id="sn" handlechange={handleChange}/>
				<TextInput label="Mac adress"  id="mac" handlechange={handleChange}/>
				<SelectInput label="Model"  id="model" handlechange={handleChange} />
				<ButtonGroup d="flex" justifyContent="flex-end">
					<Button variant="outline" onClick={onCancel}>
            Cancel
					</Button>
					<Button isDisabled={sn.trim() === '' || model.trim() === '' || mac.trim() === ''}
						colorScheme="red" 
						onClick={handleSubmit as any}
					>
            Save
					</Button>
				</ButtonGroup>
			</Stack>
		)
	}
	return (
		<>
			<Box as='button' ml='1em' onClick={onOpen} >
				<IconButton aria-label='button' size="sm" colorScheme='red' icon={<AddIcon />} />
			</Box>
			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
			>
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Create user</DrawerHeader>

					<DrawerBody>
						<Form firstFieldRef={firstFieldRef} onCancel={onClose} dispatch={dispatch}/>
					</DrawerBody>

                    
				</DrawerContent>
			</Drawer>
		</>
	)
}


