/* eslint-disable @typescript-eslint/no-explicit-any */
import { CheckIcon, EditIcon } from '@chakra-ui/icons'
import { ButtonGroup, IconButton, Flex, EditablePreview, EditableInput,Editable, useEditableControls } from '@chakra-ui/react'


export default function EditableText({text}:any) {

	function EditableControls() {
		const {
			isEditing,
			getSubmitButtonProps,
			getEditButtonProps,
		} = useEditableControls()

		return isEditing ? (
			<ButtonGroup justifyContent="center" size="sm" ml='1em'>
				<IconButton aria-label='' icon={<CheckIcon />} {...getSubmitButtonProps()} />
			</ButtonGroup>
		) : (
			<Flex justifyContent="center" ml='2em'>
				<IconButton aria-label='' size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
			</Flex>
		)
	}


	return (
		<Editable defaultValue={text} isPreviewFocusable={false} ml='auto'>
			<Flex>
				<EditablePreview />
				<EditableInput w='60'/>
				<EditableControls />
			</Flex>
		
		</Editable>
	)
}
