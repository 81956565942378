/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar, Box, Button, Divider, Flex, Img, Menu, MenuButton, MenuItem, MenuList, Progress, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import DrawerBar from '../sidebar/DrawerBar'
import { signout } from '../../../../redux/actions/actions'
import { ArrowBackIcon, ChevronDownIcon, SettingsIcon } from '@chakra-ui/icons'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'
import logo from '../../../../logoOA.svg'
import { useHistory } from 'react-router'

export default function Topbar() {

	const [mobileDrawer] = useMediaQuery('(max-width: 48em)')
	const [isMobile] = useMediaQuery('(min-width: 48em)')
	const { user,pfpURL  } = useSelector((state: RootState) => state.auth)
	const dispatch = useDispatch()
	const history = useHistory()
	
	return (
		<>
			<Stack height='full' m='auto' ml={5} alignItems='flex-start' justifyContent='center' > 
				

				<Flex direction='row' >
					{ mobileDrawer ?
						(<DrawerBar />)
						: null}
					<Img src={logo} width={['80px','120px']}/>
					
				</Flex>
			</Stack>
			<Stack mr={5} mt={2} mb={2} zIndex={100} >
				{isMobile ? 
					<Menu >
						<MenuButton bg='white' color='black' _hover={{bg:'gray.200'}} borderRadius='0' as={Button} rightIcon={<ChevronDownIcon />} bgColor='white'>
                     Get started
						</MenuButton>
						<MenuList borderRadius='0'>
							<MenuItem icon={<RiCheckboxCircleFill/>}>Verify your email</MenuItem>
							<Divider m={2}/>
							<MenuItem icon={<RiCheckboxBlankCircleLine/>}>Add a new centrifuge</MenuItem>
							<Divider m={2}/>
							<MenuItem icon={<RiCheckboxBlankCircleLine/>}>Mark as Draft</MenuItem>
						</MenuList>
					</Menu>
					: null }
				<Progress value={33} size="xs" colorScheme="red" bgColor='gray.200'/>
			</Stack>

			<Stack display='flex' flexDirection='row' zIndex={100}>
				<Stack display='flex' alignItems='center' justifyContent='center'>
					<Menu>
						<MenuButton as={Flex} borderRadius='0'>
							<Flex>
								<Avatar name={user?.username as string} src={pfpURL}/>
								<Flex flexDirection='row' justifyContent='center' alignItems='center'>
									{ isMobile ? 
										<Box ml="3">
											<Text fontWeight="bold">
												{user?.username}
											</Text>
											<Text fontSize="sm">{user?.email}</Text>
										</Box>
										: null}
									<ChevronDownIcon ml={3}/>
								</Flex>
							</Flex>
						</MenuButton>
						<MenuList borderRadius='0'>
							{ isMobile ? null
								: 
								<>
									<Menu>
										<MenuButton bg='white' color='black' _hover={{bg:'gray.200'}} borderRadius='0' as={Button} rightIcon={<ChevronDownIcon />} bgColor='white'>
                     Get started
										</MenuButton>
										<MenuList borderRadius='0'>
											<MenuItem icon={<RiCheckboxCircleFill/>}>Verify your email</MenuItem>
											<Divider m={2}/>
											<MenuItem icon={<RiCheckboxBlankCircleLine/>}>Add a new centrifuge</MenuItem>
											<Divider m={2}/>
											<MenuItem icon={<RiCheckboxBlankCircleLine/>}>Mark as Draft</MenuItem>
										</MenuList>
									</Menu>
									<MenuItem>{user?.username}</MenuItem>
									<Divider/>
								</>}
							<MenuItem icon={<SettingsIcon/>} onClick={()=> history.push('dashboard/settings')}>
                                    Account settings</MenuItem>
							<MenuItem 
								onClick={() => dispatch(signout())}
								icon={<ArrowBackIcon/>}>Log out</MenuItem>
						</MenuList>
					</Menu>
				</Stack>
			</Stack>
		</>
	)
}
