import { EmailIcon } from '@chakra-ui/icons'
import { Flex, VStack, Img, Heading, Button, InputGroup, InputLeftElement, Input, Alert, AlertIcon, useMediaQuery } from '@chakra-ui/react'
import { useState, FormEvent } from 'react'
import logoSVG from '../../logoOA.svg'
import logoEslogan from '../../logo-ortoalresa.png'
import loginImage from '../../heroHome.jpeg'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sendPswdResetEmail, setError} from '../../redux/actions/actions'
import { RootState } from '../../redux/store'

export default function Recover() {

	const [isMobile] = useMediaQuery('(max-width: 48em)')
	const history = useHistory()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const { success,error } = useSelector((state: RootState) => state.auth)
	const [formData, setFormData] = useState({
		email: ''
	})
	const { email } = formData
	const handleChange = (e: { target: { name: string; value: string } }) =>{
		setFormData({...formData,
			[e.target.name] : e.target.value
		})
	}
	const submitForm =(e: FormEvent) =>{
		e.preventDefault()
		if(email.trim() === ''){
			return dispatch(setError('Please, complete all the fields'))
		}
		setLoading(true)
		dispatch(sendPswdResetEmail(email,'Email sent correctly', ()=> setLoading(false)))
		setTimeout(() => {
			setLoading(false)
        
		}, 500)
	}

	return (
		<Flex height={isMobile ? '100%' : '100vh'} alignItems='center' background='white' justifyContent={'center'} bgImage={loginImage} backgroundSize='cover' bgPos={-200} bgRepeat='no-repeat'>
			<Flex direction='row-reverse' justifyContent='revert' width='100%' height='full'>
				<Flex height='100%' overflowY='scroll' width={['100%','100%','40%']} maxW='800' minW='300'
					background='white' justifyContent='center' alignItems='center'>
                
					<VStack w='100%'>
						<Flex direction='column' maxW='400px' w='100%' justifyContent='center' p={8} pt={10} pb={10}>
							{isMobile ?
								<Img src={logoSVG}  w='3xl' p={8}/> : <Img src={logoEslogan} maxW={['200px','200px','250px','350px']} w='100%'
									position='absolute' left='0' bottom={32} bg='whiteAlpha.600'
									p={12}/>}
							<Heading mb={6}>Recover password</Heading>  

							<InputGroup>
								{/* eslint-disable-next-line react/no-children-prop*/}
								<InputLeftElement children={<EmailIcon/>} />
								<Input placeholder='name@mail.com' 
									autoComplete='username' 
									variant='filled' mb={3} 
									type='email'
									name='email'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
							</InputGroup>
							<Button colorScheme='red' type='submit' 
								onClick={submitForm} isLoading={loading}  
								borderRadius='0'
							>Send recovery email</Button>
							<Button fontSize='sm' variant='link' borderRadius='0'
								mt={3} onClick={()=> history.push('/log-in')}>Log in</Button>
							<Button fontSize='sm' variant='link' borderRadius='0'
								mt={3} onClick={()=> history.push('/register')}>Create new account</Button>
						</Flex>
						{ error 
							? <Alert mb={3} rounded={8} status="error" maxW={['350px','350px','300px','400px']}>
								<AlertIcon />
								{error}
							</Alert> 
							: null }
						{ success 
							? <Alert mb={3} rounded={8} status="success" maxW={['350px','350px','300px','400px']}>
								<AlertIcon />
								{success}
							</Alert> 
							: null }
					</VStack>
				</Flex>
			</Flex>
		</Flex>
	)
}
