/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, Heading,Text,Table,Tr,Th,Thead,Tbody,Td, useMediaQuery, Spacer, Skeleton } from '@chakra-ui/react'
import { collection, onSnapshot } from 'firebase/firestore'
import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { db } from '../../../../firebase/config'
import { RootState } from '../../../../redux/store'
import { DELETE_USER } from '../../../../urlConfig'
import DeleteButton from '../../../DeleteButton'
import UserAvatar from '../../admin/UserAvatar'
import CreateUserButton from '../CreateUserButton'

export default function Admin() {
	const [isMobile] = useMediaQuery('(max-width: 33em)')
	const [ users, setUsers ] = useState<any>([])
	const [loaded, setLoaded] = useState(false)
	const {  user } = useSelector((state: RootState) => state.auth)
	useEffect(() => {
		//const docRef = firebase.firestore().collection('users')
		// docRef.onSnapshot(docSnapshot =>{
		// 	setUsers(docSnapshot.docs.map(doc => doc.data()))
		// }, err => {
		// 	console.log(`Encountered error: ${err}`)
		// })
		
		onSnapshot(collection(db,'users'), (querySnapshot:any) => {
			setUsers(querySnapshot.docs.map((doc:any)=> doc.data())) // <---- Revisa
		})
	}, [])
	
        
	
	return (
		<Flex height='fit-content' minHeight='89vh' width='100vw' overflowX='auto'margin={5} borderRadius={8} flexDirection='column'>
			<Heading pl={5} pt={5} fontSize='18px'>ADMIN</Heading>
			<Box width={ isMobile ? 'fit-content' : 'null'} m={1}  bg='white' shadow='base' pt={5}>
				<Skeleton isLoaded={loaded}>

					<Flex alignItems='center' mb='1em'>
						<Heading ml='2em' fontSize='18px'>Users</Heading>
						<CreateUserButton isMobile={isMobile}/>   
					</Flex>
					<Flex>
						{ users !== [] ?
							( <Table variant="simple" size='sm'>
								<Thead>
									<Tr>
										<Th>Username</Th>
										{isMobile ? null :<Th>E-mail</Th>}
										<Th>Role</Th>
									</Tr>
								</Thead>

								<Tbody>

									{users.map((doc: { username: string, pfp: string, role: string, email:string, id:string}) =>
										(
											<>
												<Tr>
													<Td>
														<Flex>
															<UserAvatar doc={doc} setLoaded={setLoaded} isMobile={isMobile}/>
															<Flex direction='column'>
																<Text mt='auto' mb='auto' ml='1em'>{doc.username}</Text> 
																{isMobile ? <Text ml='1em'>{doc.email}</Text> : null}
															</Flex>
														</Flex>   
                                
													</Td>
													{isMobile ? null : <Td>{doc.email}</Td>}
													<Td>
														<Flex alignItems='center'>
															{doc.role}
															<Spacer/>
															{ doc.id as any === user?.id ? null :
																<DeleteButton url={DELETE_USER} doc={{id: doc.id}} delete/>
															}
														</Flex>
													</Td>
												</Tr>
											</>
										)
									)}
								</Tbody>
							</Table>)
							: null}
					</Flex>
				</Skeleton>
			</Box>
		</Flex>
	)
}
