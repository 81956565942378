import { AuthAction, AuthState, CLEAR_STATE, GET_URL, NEED_VERIFICATION, SET_ERROR, SET_INFO, SET_LOADING, SET_SUCCESS, SET_USER, SIGN_OUT } from '../types/types'

const initialState: AuthState = {
	user: null,
	authenticated: false,
	loading: false,
	error: '',
	needVerification: false,
	success: '',
	pfpURL:''
}
export default (state = initialState, action: AuthAction) => {
	switch (action.type) {
	case SET_USER:
		return{
			...state,
			user: action.payload,
			authenticated: true
		}
	case SET_LOADING:
		return{
			...state,
			loading: action.payload
		}
	case SIGN_OUT:
		return{
			...state,
			user: null,
			authenticated: false,
			pfpURL:'',
			loading: false
		}
	case SET_ERROR:
		return{
			...state,
			error: action.payload
		}
	case NEED_VERIFICATION:
		return{
			...state,
			needVerification: true
		}
	case SET_INFO:
		return{
			...state,
			error: '',
			success: action.payload
		}
	case SET_SUCCESS:
		return{
			...state,
			success: action.payload
		}
	case CLEAR_STATE:
		return{
			...state,
			authenticated: false,
			error: '',
		}
	case GET_URL:
		return{
			...state,
			pfpURL:action.payload
		}
	default:
		return state
	}
}