/* eslint-disable @typescript-eslint/no-explicit-any */
//Ruta privada accesible sólo por usuarios autenticados

import { FC } from 'react'
import { useSelector } from 'react-redux'
import {  Redirect, Route, RouteProps } from 'react-router-dom'

import { RootState } from '../redux/store'

interface Props extends RouteProps{
    component: any;
}
const PrivateRoute: FC<Props> = ({component: Component, ...rest}:any) =>{
	const { authenticated } = useSelector((state: RootState) => state.auth)
	return(
		<Route {...rest} render={props => authenticated ? <Component {...props}/> : <Redirect to='/'/>}/>
	)
}

export default PrivateRoute