import {  Flex, Heading, Img, Stack  } from '@chakra-ui/react'
import React from 'react'
import logoEslogan from '../../logo-ortoalresa.png'
export default function Main() {
    
	return (
        
		<Flex height='100vh' alignItems='center' background='white' justifyContent={'center'}>
			<Stack direction={['column', 'column', 'column','column','row']}  spacing={['10vw','10vw','10vw','10vw','30vw']} alignItems='center'>
				<Img src={logoEslogan} maxW={['150px','150px','200px','250px','300px']} objectFit='contain'/>
             
				<Heading> 404 - Page not found</Heading>
			</Stack>
            
		</Flex>
	)
}
