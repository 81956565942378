import { Global } from '@emotion/react'

const Fonts = () => (
	<Global
		styles={`
      /* latin */
      @font-face {
        font-family: 'Helvetica Neue';
        font-weight: 200;
        src: url('../fonts/HelveticaNeue.woff2') format('woff2'), url('../fonts/HelveticaNeue.woff2') format('woff2');
      }
      @font-face {
        font-family: 'Helvetica Neue Bold';
        font-weight: 700;
        src: url('../fonts/HelveticaNeueBold.woff2') format('woff2'), url('../fonts/HelveticaNeueBold.woff2') format('woff2');
      }
      `}
	/>
)

export default Fonts