/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text, Flex, Heading, VStack, Avatar,Button, Divider, Box } from '@chakra-ui/react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import EditableText from '../../../EditableText'
import { getPfp } from '../../../../redux/actions/actions'
import Swal from 'sweetalert2'
import { getStorage, uploadBytes,ref } from '@firebase/storage'
import styled from '@emotion/styled'

const Input = styled.input`
    position: absolute;
    min-width: 80%;
    min-height: 100%;
    font-size: 7px;
    text-align: right;
    -webkit-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
`
export default function Settings() {
	const {  user ,pfpURL } = useSelector((state: RootState) => state.auth)
	const [image , setImage] = useState<any>('')
	const storage = getStorage()
	const dispatch = useDispatch()
	const metadata = {
		name:'pfp',
		contentType: 'image/jpeg',
	}
	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 5000,
		timerProgressBar: false,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})
	const upload = ()=>{
		if(image === '') return
		const fileRef = ref(storage, `/images/${user?.id}/pfp`)
		uploadBytes(fileRef,image,metadata).then( Toast.fire({
			icon: 'success',
			title: 'Profile picture changed'
		})as any)
		setTimeout(() => {
			dispatch(getPfp(user?.pfp))
		}, 2500);
		(document.getElementById('fileUpload')as HTMLFormElement).value = ''
	}
	const handleChange = (e:any)=>{
		setImage(e.target.files[0])
	}
	return (
		<Flex height='fit-content' minHeight='89vh' width='100vw' p={3}  flexDirection='column' >
			<Heading pl={5} pt={5} fontSize='18px'>SETTINGS</Heading>
			<VStack p={['0','2']} align='stretch' background='white' pt={5} m={['0','20px auto auto auto']} mt={5}  shadow='base' w='fit-content' >
				<Text m={3} fontSize='18' fontWeight='bold'>Account settings</Text>
				<Divider/>
				<Flex flexDirection={['column','row']}  alignItems={['left','center']} w={['340px','500px']}  pt='4'>
					<Text m={3} fontSize='15' fontWeight='bold' >Profile picture</Text>
					<Flex flexDirection='column' justifyContent={['center','right']} ml={['0','auto']}>
						<Avatar size='xl' m={2} ml='3em' src={pfpURL} name={user?.username as string} />
						<Button colorScheme='red' color='white' maxW='180px' position='relative' ml={['4.5em','0']}>
							Browse...
							<Input type="file" id='fileUpload' accept="image/png, image/jpeg,image/jpg" onChange={handleChange}/>
						</Button>

					</Flex>
					
				</Flex>
				<Divider p='2'/>
				<Flex flexDirection={['column','row']}  alignItems={['left','center']}  w={['340px','500px']} p='2' >
					<Text m={3}  fontSize='15' fontWeight='bold'>Display name</Text>
					<EditableText text={user?.username} />
				</Flex>
				<Divider/>
				<Flex flexDirection={['column','row']}  alignItems={['left','center']}  w={['340px','500px']} p='2'>
					<Text m={3} fontSize='15' fontWeight='bold'>Email</Text>
					<EditableText text={user?.email} />
                
				</Flex>
				<Divider/>
				<Flex flexDirection={['column','row']}  alignItems={['left','center']}  w={['340px','500px']} p='2'>
					<Text m={3} fontSize='15' fontWeight='bold'>Password</Text>
					<Button ml='auto' mr={['auto','2em' ]}variant='outline'>Send reset email</Button>
				</Flex>
				<Divider/>
				<Flex flexDirection={['column','row']} alignItems={['left','center']}  w={['340px','500px']} p='2'>
					<Button colorScheme='red' ml='auto' mr={['auto','2em' ]} isDisabled={image === ''} onClick={upload}>Save changes</Button>
                
				</Flex>
			</VStack>

		</Flex>
	)
}
