import {
	ChakraProvider
} from '@chakra-ui/react'
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import Main from './components/pages/Main'
import Dashboard from './components/pages/Dashboard'
import NotFound from './components/pages/NotFound'
import Register from './components/pages/Register'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './redux/store'
import Loader from './components/Loader'
import { getAuth, onAuthStateChanged} from 'firebase/auth'
import { FC, useEffect } from 'react'
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import { getUserById, setLoading, setNeedVerification } from './redux/actions/actions'

import Fonts from './theme/Fonts'
import theme from './theme/Theme'
import Recover from './components/pages/Recover'

const App:FC = () => {
	const dispatch = useDispatch()
	const { loading } = useSelector((state:RootState) => state.auth)
	const auth = getAuth()
	useEffect(() => {
		dispatch(setLoading(true))
		const unsuscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				dispatch(setLoading(true))
				await dispatch(getUserById(user.uid))
				if (!user.emailVerified) {
					dispatch(setNeedVerification())
				}
			}
			dispatch(setLoading(false))
		})

		return () => {
			unsuscribe()
		}
	}, [dispatch])

	if (loading) {
		return <Loader/>
	}
	return (
		<ChakraProvider theme={theme}>
			<Fonts/>
			<Router>
				<Switch>
					<Redirect exact from='/' to ='/log-in'/>
					<PublicRoute path='/log-in' component={Main}/>
					<PublicRoute path='/register' component={Register}/>
					<PublicRoute path='/recover-password' component={Recover}/>
					<PrivateRoute path='/dashboard' component={Dashboard}/>
					<PublicRoute path="*" component={NotFound}/>
				</Switch>
			</Router>
		</ChakraProvider>
	)
}

export default App
