/* eslint-disable react/no-children-prop */
import { Alert, AlertIcon, Button, Divider, Flex, Heading, Icon, Img, Input, InputGroup, InputLeftElement, InputRightElement, useMediaQuery, VStack } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import logoSVG from '../../logoOA.svg'
import logoEslogan from '../../logo-ortoalresa.png'
import loginImage from '../../heroHome.jpeg'
import { useHistory } from 'react-router-dom'
import { EmailIcon, LockIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { setError, signin, SocialSignIn  } from '../../redux/actions/actions'
import { FcGoogle } from 'react-icons/fc'
import { FaMicrosoft } from 'react-icons/fa'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
export default function Main () {
	const [isMobile] = useMediaQuery('(max-width: 48em)')
	const history = useHistory()
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [show, setShow] = useState(false)
	const handleClick = () => setShow(!show)
	const { error } = useSelector((state: RootState) => state.auth)
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	})
	const { email, password } = formData
	const handleChange = (e: { target: { name: string; value: string } }) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		})
	}
	const submitForm = (e: FormEvent) => {
		e.preventDefault()
		if (email.trim() === '' || password.trim() === '') {
			return dispatch(setError('Please, complete all the fields'))
		}
		setLoading(true)
		dispatch(signin({ email, password }, () => setLoading(false)))
	}
	const withGoogle = () => {
		const provider = new GoogleAuthProvider()
		dispatch(SocialSignIn(() => setLoading(false),provider,'Google'))
	}
	const withMicrosoft = () => {
		const provider = new OAuthProvider('microsoft.com') 
		dispatch(SocialSignIn(() => setLoading(false),provider,'Microsoft'))
	}
	return (
		<Flex height={isMobile ? '100%' : '100vh'} alignItems='center' background='white' justifyContent={'center'} bgImage={loginImage} backgroundSize='cover' bgPos={-200} bgRepeat='no-repeat'>
			<Flex direction='row-reverse' justifyContent='revert' width='100%' height='full'>
				<Flex height='100%' overflowY='scroll' width={['100%', '100%', '40%']} maxW='800' minW='300'
					background='white' justifyContent='center' alignItems='center'>

					<VStack w='100%'>
						<Flex direction='column' maxW='400px' w='100%' justifyContent='center' p={8} pt={10} pb={10}>
							{isMobile
								? <Img src={logoSVG} w='3xl' p={8}/>
								: <Img src={logoEslogan} maxW={['200px', '200px', '250px', '350px']} w='100%'
									position='absolute' left='0' bottom={32} bg='whiteAlpha.600'
									p={12}/>}
							<Heading mb={6}>Log in</Heading>
							<Button mb={2} colorScheme='gray' variant='outline' color='black' leftIcon={<FcGoogle />} borderRadius='0'
								onClick={withGoogle} isLoading={loading}>
                            Log in with Google
							</Button>
							<Button colorScheme='twitter' color='white' leftIcon={<FaMicrosoft />} borderRadius='0'
								onClick={withMicrosoft} isLoading={loading}>
                            Log in with Microsoft
							</Button>
							<Divider mt={3} mb={3}/>

							<InputGroup>
								<InputLeftElement children={<EmailIcon/>} />
								<Input placeholder='name@mail.com'
									autoComplete='username'
									variant='filled' mb={3}
									type='email'
									name='email'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
							</InputGroup>
							<InputGroup>
								<InputLeftElement children={<LockIcon/>} />
								<Input placeholder='*********'
									autoComplete='current-password'
									variant='filled' mb={6}
									type={show ? 'text' : 'password'}
									name='password'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
								<InputRightElement>
									<Icon onClick={handleClick} as={show ? AiFillEyeInvisible : AiFillEye}/>
								</InputRightElement>
							</InputGroup>
							<Button colorScheme='red' type='submit'
								onClick={submitForm} isLoading={loading}
								borderRadius='0'
							>Log in</Button>
							<Button fontSize='sm' variant='link' borderRadius='0'
								mt={3} onClick={() => history.push('/register')}>Create new account</Button>
							<Button fontSize='sm' variant='link' borderRadius='0'
								mt={3} onClick={() => history.push('/recover-password')}>Forgot password?</Button>
						</Flex>
						{ error
							? <Alert mb={3} rounded={8} status="error" maxW={['350px', '350px', '300px', '400px']}>
								<AlertIcon />
								{error}
							</Alert>
							: null }
					</VStack>
				</Flex>
			</Flex>
		</Flex>
	)
}
