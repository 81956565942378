/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Avatar, Flex, Heading, Divider,Text, Img, Button } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import graph from '../../../../graph.svg'
import Device from './Devices/Device'
import { useHistory } from 'react-router'

export default function Home({devices}:any) {
	const {  user,pfpURL } = useSelector((state: RootState) => state.auth)
	const history = useHistory()
	return (
		<Flex height='89vh' width='100vw'  padding={[0,3]} borderRadius={8} flexDirection={['column','row']} >
			<Flex direction='column' w={['100%','60%']} mb={[10,0]}>
				<Heading pl={5} pt={5} fontSize='18px'>HOME</Heading>
				<Box p={5} maxWidth='500' maxHeight='700' height='100%' w='100%'>
					<Box background='whiteAlpha.800' maxHeight='100' zIndex='0'  height='100%'>
						<Img src={graph} width='175px' position='relative' ml='40%' zIndex='0'/>
						<Avatar border='4px' color='gray.200' src={pfpURL} size='xl' position='relative' zIndex='2' top='-20' left='10'/>
					</Box>
					<Box background='white' maxWidth='500' shadow='base' maxHeight='150' height='100%' w='100%' position='relative' zIndex='1' p='14' pl={['0','5']}>
						<Flex direction='row' >
							<Flex direction='column' alignItems='end' p='3' pr={['0','3']} pl={['4','0']}>
								<Text fontSize='18' isTruncated maxWidth={['80px','150px']}>{user?.username}</Text>
								<Text fontSize='12' color='gray'>{user?.role.toUpperCase()}</Text>
							</Flex>
							<Flex direction='column' ml='2' p='3'pr={['0','3']} pl={['0','3']}>
								<Text fontSize='18' >{devices.length}</Text>
								<Text fontSize='12' color='gray' >DEVICES</Text>
							</Flex>
							<Flex direction='column' ml={['3em','0em']} p='3' pr={['0','3']} pl={['0','3']} >
								<Button bg='white' color='black' _hover={{bg:'gray.200'}} borderRadius='0'> Profile &gt;&gt;</Button>
							</Flex>
						</Flex>
					</Box>
                        
				</Box>
			</Flex>
			<Flex direction='column' w='100%' >
				<Heading pl={5} pt={[0,5]} fontSize='18px'>DEVICES</Heading>
				<Box p={5} maxWidth='800' maxHeight='700'  height='100%' w='100%'>
					{devices.length !== 0 ? 
						<Box background='white' maxWidth='800' shadow='base' maxHeight='580' height='fit-content' w='100%'>
							{ devices !== [] ?
								(<ul>
									{devices.map((doc: { model: string, image: string, sn: string,
                        reference:string, voltage:string,frequency:string}, index: number) => 
										index < 2 && (
											<Fragment key={doc.sn}>
												<Device title={doc.model} src={doc.image} 
													subtitle={doc.reference} voltage={doc.voltage} 
													frequency={doc.frequency} sn={doc.sn}
													onClick={()=> history.push(`/dashboard/devices/${doc.sn}`)}/>
												<Divider mt={3} mb={3}/>
											</Fragment>
										)
									) }
								</ul>) : null  
							}
						</Box>
						: 
						<Box background='white' maxWidth='800' shadow='base' maxHeight='350' height='100%' w='100%'>
							<Flex height='100%' textAlign='center' justifyContent='center' alignItems='center' flexDirection='column'>   
								<Text fontSize='18'> You don&apos;t have any centrifuge yet!</Text>
								<Button m={2} onClick={()=> history.push('dashboard/devices')}> Try adding one &gt;&gt;</Button>
							</Flex>
						</Box>
					}
				</Box>
			</Flex>
		</Flex>
	)
}
