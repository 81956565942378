/* eslint-disable @typescript-eslint/no-explicit-any */
export const SET_USER = 'SET_USER'
export const SIGN_OUT = 'SIGN_OUT'
export const SET_INFO = 'SET_INFO'
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const NEED_VERIFICATION = 'NEED_VERIFICATION'
export const CLEAR_STATE = 'CLEAR_STATE'
export const SET_SUCCESS = 'SET_SUCCESS'
export const GET_CENTRIFUGE = 'GET_CENTRIFUGE'
export const GET_URL = 'GET_URL'

export interface User{
    username: string | null;
    pfp: string | null;
    email: string | null;
    id: [any];
    createdAt: any;
    role: string;
}
export interface AuthState{
    user: User | null;
    authenticated: boolean;
    loading: boolean;
    error: string;
    needVerification: boolean;
    success: string;
    pfpURL: string;
}
export interface SingUpData{
    username: string;
    email: string;
    password: string;
}
export interface SingInData{
    email: string;
    password: string;
}
export interface Centrifuge{
    user_name: string | undefined;
    user_id: string | undefined;
    createdAt: any;
    model: string;
    serial_number: string;
    reference: string;
}
// Actions
interface SetClearSate{
    type: typeof CLEAR_STATE;
}
interface GetCentrifuge{
    type: typeof GET_CENTRIFUGE;
    payload: Array<any>;
}
interface GetUrl{
    type: typeof GET_URL;
    payload: string;
}
interface SetUserAction{
    type: typeof SET_USER;
    payload: User;
}
interface SetLoadingAction{
    type: typeof SET_LOADING;
    payload: boolean;
}
interface SingOutAction{
    type: typeof SIGN_OUT;
}
interface SetInfoAction{
    type: typeof SET_INFO;
    payload: string;
}
interface SetErrorAction{
    type: typeof SET_ERROR;
    payload: string;
}
interface NeedVerificationAction{
    type: typeof NEED_VERIFICATION;
}
interface SetSuccessAction{
    type: typeof SET_SUCCESS;
    payload: string;
}

export type AuthAction = SetUserAction | SetLoadingAction | SingOutAction | SetErrorAction | NeedVerificationAction | SetSuccessAction | SetInfoAction | SetClearSate | GetCentrifuge | GetUrl ;