/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import Swal from 'sweetalert2'
import { ref,listAll, getStorage } from 'firebase/storage'

export default function DeleteButton({url,del,doc}:any) {
	const Toast = Swal.mixin({
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 4000,
		timerProgressBar: false,
		didOpen: (toast) => {
			toast.addEventListener('mouseenter', Swal.stopTimer)
			toast.addEventListener('mouseleave', Swal.resumeTimer)
		}
	})
	const storage = getStorage()
	const deleteData = () =>{
		const storageRef = ref(storage,`/images/${doc.id}/`)
		listAll(storageRef).then((listResults:any) => {
			const promises = listResults.items.map((item:any) => {
				return item.delete()
			})
			Promise.all(promises)
		})
	}
	return (
		<Button colorScheme='red' color='white' width='2'ml={1} onClick={()=> Swal.fire({
			title: 'Are you sure?',
			text: 'You won\'t be able to revert this!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#47494c',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it'
		}).then((result) => {
			if (result.isConfirmed) {
				del ? deleteData : null
				fetch(url,{
					method:'POST',
					mode: 'cors',
					body: JSON.stringify(doc)
				}).then(res => {
					res.json().then(data =>{
						res.status === 200 ? 
							Toast.fire({
								icon: 'success',
								title: data.msg
							}) : null
						res.status >= 400 ?
							Toast.fire({
								icon: 'error',
								title: data.msg
							}) : null
					})	
				})
			}
		})}>
			<DeleteIcon/>
		</Button>
	)
}
