/* eslint-disable @typescript-eslint/no-explicit-any */
import { Image, Flex, Heading, Text, useMediaQuery, HStack,VStack, Skeleton } from '@chakra-ui/react'
import { query, collection, where, onSnapshot } from '@firebase/firestore'
import {  Fragment, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { db } from '../../../../../firebase/config'
export default function DeviceDetail() {
	const [isMobile] = useMediaQuery('(max-width: 33em)')
	const location = useLocation()
	const segments = location.pathname.split('/')
	const last = segments.pop() || segments.pop()
	const [loaded, setLoaded] = useState(false)
	const [device, setDevice] = useState<any>({})
	useEffect(() => {
		const q = query(collection(db, 'devices'), where('sn', '==', last))
		onSnapshot(q, (querySnapshot) => {
			querySnapshot.docs.map(doc => setDevice(doc.data()))
		})
	}, [])
	// firebase.firestore().collection('devices').where('sn','==', last).onSnapshot(docSnapshot =>{
	// 	docSnapshot.docs.map(doc => setDevice(doc.data()))
	// }, err => {
	// 	console.log(`Encountered error: ${err}`)
	// })
	return (
		<Flex height='fit-content' minHeight='89vh' width='100vw' p={3}flexDirection='column'>
			<Heading pl={5} pt={5} fontSize='18px'>HOME</Heading>
			<HStack p={2} align='stretch' background='white' pt={5} mt={5} shadow='base' >
				<Skeleton isLoaded={loaded}>
					<Flex flexDirection='row'  alignItems='center'>
						<VStack>
							<Text m={3} fontSize='18' fontWeight='bold'>{device.model}</Text>
							<Image borderRadius="lg" w={32} src={device.image} m='auto' onLoad={() => setLoaded(true)} loading='lazy'/>
           
						</VStack>
						{isMobile ? 
							(
								<VStack ml='2em'>
									<Text><b>Reference: </b>{device.reference}</Text> 
									<Text><b>Status: </b>{device.working === false ? 'Idle': 'Working' }</Text> 
									<Text><b>Speed: </b>{device.speed}</Text> 
									<Text><b>Temp: </b>{device.temp}</Text> 
									<Text><b>Unbalance: </b>{device.unbalance}</Text> 
									<Text><b>Voltage: </b>{device.voltage}</Text> 
									<Text><b>Lid: </b>{device.reference}</Text> 
								</VStack>
							)
							: 
							(<>
								<VStack ml='2em'>
									<Text><b>Reference: </b>{device.reference}</Text> 
									<Text><b>Status: </b>{device.working === false ? 'Idle': 'Working' }</Text> 
									<Text><b>Speed: </b>{device.speed}</Text> 
								</VStack>
								<VStack ml='2em'>
									<Text><b>Temp: </b>{device.temp}</Text> 
									<Text><b>Unbalance: </b>{device.unbalance}</Text> 
									<Text><b>Voltage: </b>{device.voltage}</Text> 
									<Text><b>Lid: </b>Open </Text> 
								</VStack>
							</>
							) }
					</Flex>

				</Skeleton>
			</HStack>
		</Flex>
	)
}