// Spinner mostrado cuando el valor isLoading del estado es verdadero

import { CircularProgress, Flex, useMediaQuery }from '@chakra-ui/react'
export default function Loader() {
	const [isMobile] = useMediaQuery('(max-width: 30em)')
	return (
		<Flex height={isMobile ? '85vh' : '100vh'} alignItems='center' background='gray.300' justifyContent={'center'}>
			<CircularProgress isIndeterminate size='80px' color="red" />
		</Flex>
	)
}
