/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Flex,
	Text,
	Icon,
	Link,
	Menu,
	MenuButton
} from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

export default function NavItem({ icon, title, active, navSize, route }:any) {
	const history = useHistory()

	return (
		<Flex
			mt={30}
			flexDir="column"
			w="100%"
			alignItems={navSize === 'small' ? 'center' : 'flex-start'}
		>
			<Menu placement="right">
				<Link
					backgroundColor={active && '#e51d1d'}
					p={3}
					borderRadius={8}
					_hover={{ textDecor: 'none', backgroundColor: '#e51d1d' }}
					onClick={()=> history.push(route) }
					w={navSize === 'large' && '100%' as any}
				>
					<MenuButton w="100%" >
						<Flex color='white'>
							<Icon as={icon} fontSize="xl" color={active ? '#ffffff' : 'white'} />
							<Text ml={5} display={navSize === 'small' ? 'none' : 'flex'}>{title}</Text>
						</Flex>
					</MenuButton>
				</Link>
				{/* <MenuList
                    py={0}
                    border="none"
                    w={200}
                    h={200}
                    ml={5}
                >
                    <NavHoverBox title={title} icon={icon} description={description} />
                </MenuList> */}
			</Menu>
		</Flex>
	)
}