/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from 'redux-thunk'
import { RootState } from '../store'
import { db } from '../../firebase/config'
import { getAuth, createUserWithEmailAndPassword,sendEmailVerification,sendPasswordResetEmail,signInWithEmailAndPassword,signInWithPopup, signOut } from 'firebase/auth'
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { SingUpData, 
	AuthAction, 
	SET_USER, 
	User, 
	SET_LOADING, 
	SIGN_OUT, 
	SingInData, 
	SET_ERROR, 
	NEED_VERIFICATION,
	SET_INFO,
	CLEAR_STATE,
	SET_SUCCESS,
	GET_URL
} from '../types/types'
const auth = getAuth()
const storage = getStorage()
/* Registro con email - Crea un usuario con los parametros que recibe y crea un usuario en la autenticación 
    de Firebase, después crea un objeto con los datos de usuario y lo guarda en la base de datos, a la vez que envía un
    correo de verificación. */
export const signup = (data: SingUpData, onError: () => void): ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch =>{
		try {
			const res = await createUserWithEmailAndPassword(auth,data.email, data.password)
			if (res.user) {
				const userData: User = {
					email: data.email,
					pfp:`gs://ortoalresa-digital-dev.appspot.com/images/${res.user.uid}/pfp`,
					username: data.username,
					id: [res.user.uid],
					createdAt: serverTimestamp(),
					role:'user'
				}
				setDoc(doc(db,'users',res.user.uid),userData)
				setDoc(doc(db,'programs',res.user.uid),{
					name: data.username,
					uid: res.user.uid,
					centrifuges: []
				})
				// db.collection('/users').doc(res.user.uid).set(userData)
				// db.collection('/programs').doc(res.user.uid).set({
				// 	name: data.username,
				// 	uid: res.user.uid,
				// 	centrifuges: []
				// })

				sendEmailVerification(res.user)
				dispatch({
					type: NEED_VERIFICATION
				})
				dispatch({
					type: SET_INFO,
					payload: 'User created correctly. Please, verify your email'
				})

			}
		} catch (error:any) {
			onError()
			console.log(error)
			dispatch({
				type: SET_ERROR,
				payload: error.code
			})
		}
	}
}

// Inicio de sesión con email
export const signin = (data: SingInData, onError: ()=>void):ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch => {
		try {
			await signInWithEmailAndPassword(auth,data.email,data.password)
		} catch (error:any) {
			console.log(error)
			onError()
			dispatch(setError(error.code))
		}
	}
}
/* Inicio de sesión con Google - Abre un pop up para iniciar sesión con una cuenta de google y recibe datos del 
    usuario, crea un objeto y lo registra en la base de datos, después de comprobar si el usuario ya existe.*/
export const SocialSignIn = (onError: () => void,provider:any,social:any): ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch =>{
		try {
			await signInWithPopup(auth,provider).then(async (result) => {
				/** @type {firebase.auth.OAuthCredential} */
				// The signed-in user info.
				const user: any = result.user
				if (user) {
					const userData: User = {
						email: user.email,
						pfp: `gs://ortoalresa-digital-dev.appspot.com/images/${user.uid}/pfp`,
						username: user.displayName,
						id: [user.uid],
						createdAt: serverTimestamp(),
						role: 'user' //Rol usuario por defecto
					}
					
					//setDoc(doc(db,'users'),userData)

					//const ref = await db.collection('users').doc(user.uid) 
					const docRef = await doc(db,'users',user.uid)
					const docSnap = await getDoc(docRef)     
					
					const existingUserData = docSnap.data() as User  // <---- Revisar
					if (docSnap.exists()) {
						dispatch({
							type: SET_USER,
							payload: existingUserData
						})
					}else{
						const metadata = {
							name:'pfp',
							contentType: 'image/jpeg',
						}
						await fetch(user.photoURL).then(res =>{
							return res.blob()
						}).then(blob =>{
							//firebase.storage().ref(`/images/${user?.uid}/pfp`).put(blob,metadata)
							const fileRef = ref(storage,`/images/${user?.uid}/pfp`)
							uploadBytes(fileRef,blob,metadata)
						})
						setDoc(doc(db,'users',user.uid),userData)
						setDoc(doc(db,'programs',user.uid),{
							name: user.displayName,
							uid: user.uid,
							centrifuges: []
						})
						// await db.collection('/users').doc(user.uid).set(userData)
						// await db.collection('/programs').doc(user.uid).set({
						// 	name: user.displayName,
						// 	uid: user.uid,
						// 	centrifuges: []
						// })
						if(social === 'Microsoft') sendEmailVerification(user)
						dispatch({
							type: SET_USER,
							payload: userData
						})
					}
				}}).catch((error) => {
				const errorMessage = error.message
				console.log(errorMessage)
			})
		} catch (err:any) {
			onError()
			dispatch({
				type: SET_ERROR,
				payload: err.message
			})
		}
	}
}
	
// Cerrár sesión
export const signout = ():ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch => {
		try {
			dispatch(setLoading(true))
			await signOut(auth)
			dispatch({
				type: SIGN_OUT
			})
		} catch (error) {
			console.log(error)
			dispatch(setLoading(false))
		}
	}
}
//Mandar correo de recuperación de contraseña
export const sendPswdResetEmail = (email: string,successMsg: string, onError: ()=>void):ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch => {
		try {
			await sendPasswordResetEmail(auth,email)
			dispatch(setSucess(successMsg))
		} catch (error:any) {
			onError()
			dispatch(setSucess(error.message))
		}
	}
}
// Muestra el spinner de carga
export const setLoading = (value: boolean):ThunkAction<void,RootState,null,AuthAction> =>{
	return dispatch => {
		dispatch({
			type: SET_LOADING,
			payload: value
		})
	}
}
// Pasa el mensaje de error al estado
export const setError = (value: string):ThunkAction<void,RootState,null,AuthAction> =>{
	return dispatch => {
		dispatch({
			type: SET_ERROR,
			payload: value
		})
	}
}

// Obtiene el usuario por id y lo establece en el estado
export const getUserById = (id: string):ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch =>{
		try {
			const docRef = await doc(db,'users',id)
			const docSnap = await getDoc(docRef) 
			//const user = await db.collection('users').doc(id).get()
			if(docSnap.exists()){
				const userData = docSnap.data() as User
				dispatch({
					type: SET_USER,
					payload: userData
				})
			}
		} catch (error) {
			console.log(error)
        
		}
	}
}
// Manda el mensaje de verificación al estado.
export const setNeedVerification = ():ThunkAction<void,RootState,null,AuthAction> =>{
	return dispatch => {
		dispatch({
			type: NEED_VERIFICATION
		})
	}
}
// Borra el estado
export const setClearState = ():ThunkAction<void,RootState,null,AuthAction> =>{
	return dispatch => {
		dispatch({
			type: CLEAR_STATE
		})
	}
}
// Envia mensaje al estado
export const setSucess = (msg: string):ThunkAction<void,RootState,null,AuthAction> =>{
	return dispatch => {
		dispatch({
			type: SET_SUCCESS,
			payload: msg
		})
	}
}
// Inicio de sesión con email
export const getPfp = (pfp:any):ThunkAction<void,RootState,null,AuthAction> =>{
	return async dispatch => {
		try {
			const pfpRef = await ref(storage,pfp)
			getDownloadURL(pfpRef).then(function(url){
				dispatch({
					type: GET_URL,
					payload: url
				})
			})
		} catch (error:any) {
			console.log(error)
			dispatch(setError(error.code))
		}
	}
}