/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Flex, Image, Link, Skeleton, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { DELETE_CENTRIFUGE } from '../../../../../urlConfig'
import DeleteButton from '../../../../DeleteButton'

export default function Device({title,src,subtitle,voltage,frequency,onClick,deletable,sn,uid}:any) {
	const [loaded, setLoaded] = useState(false)
	
	return (
		<Skeleton isLoaded={loaded}>
			<Box p={4} display={{ md: 'flex' }} alignItems='center'>
				<Box flexShrink={0}>
					<Image
						borderRadius="lg"
						width={32}
						src={src}
						margin='auto'
						onLoad={() => setLoaded(true)}
						loading='lazy'
					/>
    
				</Box>
				<Flex flexDirection='column' mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
					<Box>
						<Text
							fontWeight="bold"
							textTransform="uppercase"
							fontSize="sm"
							letterSpacing="wide"
							color="teal.600"
						>
							{title}
						</Text>
						<Link
							mt={1}
							display="block"
							fontSize={['md','lg']}
							lineHeight="normal"
							fontWeight="semibold"
							href="#"
						>
							{subtitle} / {voltage} V / {frequency} Hz
						</Link>
					</Box>
					<Flex mt={2} direction='row' justifyContent='space-around' alignItems='center'>
						<Button  bg='white' color='black' _hover={{bg:'gray.200'}} onClick={onClick} borderRadius='0'>
      See more &gt;&gt;
						</Button>
						{deletable ?
							(
								<DeleteButton url={DELETE_CENTRIFUGE} doc={{id: sn, uid: uid}}/>
							) : null}
					</Flex>
					
				</Flex>
				
			</Box>
		</Skeleton>

	)
}
