import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
	textStyles: {
		body: {
			fontFamily: 'Helvetica Neue, sans-serif',
		},
		heading: {
			fontFamily: 'Helvetica Neue Bold, sans-serif',
		},
		mono: {
			fontFamily: 'Times New Roman, sans-serif',
		},
	},
})

export default theme