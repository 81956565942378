import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
const configDev = {
	apiKey: 'AIzaSyBam7rlhnpdofW0BkLXBIgZLBPfzL7akqA',
	authDomain: 'ortoalresa-digital-dev.firebaseapp.com',
	projectId: 'ortoalresa-digital-dev',
	storageBucket: 'ortoalresa-digital-dev.appspot.com',
	messagingSenderId: '171403063379',
	appId: '1:171403063379:web:7371e24b129378be4b96a6'
}
// const configProd = {
//   apiKey: 'AIzaSyCDSMjqbRNSH56cO7-Ep7Xjwv1zAM-6KU0',
//   authDomain: 'ortoalresa-digital-produccion.firebaseapp.com',
//   projectId: 'ortoalresa-digital-produccion',
//   storageBucket: 'ortoalresa-digital-produccion.appspot.com',
//   messagingSenderId: '605527613306',
//   appId: '1:605527613306:web:ded0c2a223cec58bfd40c3'
// }

export const app = initializeApp(configDev)
export const db = getFirestore(app)