/* eslint-disable @typescript-eslint/no-explicit-any */

//Ruta pública accesible por cualquier usuario

import React,{ FC } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { RootState } from '../redux/store'
interface Props extends RouteProps{
    component: any;
}
const PublicRoute: FC<Props> = ({component: Component, ...rest}:any) =>{
	const { authenticated } = useSelector((state: RootState) => state.auth)
	return(
		<Route {...rest} render={props => !authenticated ? <Component {...props}/> : <Redirect to='/dashboard'/>}/>
	)
}

export default PublicRoute