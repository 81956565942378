/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddIcon } from '@chakra-ui/icons'
import { Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverCloseButton, ButtonGroup, Stack, FormControl, FormLabel, Input, Box, IconButton, useDisclosure, Select } from '@chakra-ui/react'

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { SET_ERROR } from '../../../redux/types/types'
import { CREATE_USER } from '../../../urlConfig'
import CreateUserDrawer from './CreateUserDrawer'


const TextInput = React.forwardRef((props:any, ref) => {
	return (
		<FormControl>
			<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
			<Input ref={ref as any} name={props.id} {...props} onChange={props.handlechange} />
		</FormControl>
	)
})
const SelectInput = React.forwardRef((props:any, ref) => {
	return (
		<FormControl>
			<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
			<Select ref={ref as any} name={props.id} {...props} onChange={props.handlechange}>
				<option value="user">User</option>
				<option value="sat">Sat</option>
				<option value="admin">Admin</option>
			</Select>
		</FormControl>
	)
})
const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 2000,
	timerProgressBar: false,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
})
 
const Form = ({ firstFieldRef, onCancel,dispatch }:any) => {

	const [formData, setFormData] = useState({
		username:'', email:'',password:'', role:'user'
	})
	const { username, password } = formData
	const handleSubmit = () =>{
		onCancel()
		try {
			fetch(CREATE_USER,{
				method:'POST',
				mode: 'cors',
				body: JSON.stringify(formData)
			}).then(res => {
				res.status === 200 ? 
					Toast.fire({
						icon: 'success',
						title: 'User Created'
					}) : 
					Toast.fire({
						icon: 'error',
						title: 'An error has ocurred'
					})
			})
		} catch (error:any) {
			dispatch({
				type: SET_ERROR,
				payload: error.message
			})
		}
	}
	const handleChange = (e: { target: { name: any; value: any } }) =>{
		setFormData({
			...formData,
			[e.target.name] : e.target.value
		})
	}
	return (
		<Stack spacing={4}>
			<TextInput
				label="Username"
				id="username"
				ref={firstFieldRef}
				handlechange={handleChange}
			/>
			<TextInput label="Email"  id="email" handlechange={handleChange}/>
			<TextInput label="Password"  id="password" handlechange={handleChange}/>
			<SelectInput label="Role"  id="role" handlechange={handleChange} />
			<ButtonGroup d="flex" justifyContent="flex-end">
				<Button variant="outline" onClick={onCancel}>
            Cancel
				</Button>
				<Button isDisabled={username.trim() === '' || password.trim() === ''}
					colorScheme="red" 
					onClick={handleSubmit as any}
				>
            Save
				</Button>
			</ButtonGroup>
		</Stack>
	)
}
  
export default function CreateUserButton({isMobile}:any) {
	const { onOpen, onClose, isOpen } = useDisclosure()
	const dispatch = useDispatch()
	const firstFieldRef = React.useRef(null)
  
	return (
		<Box ml='1em' mr='3em'>
			{ isMobile ? <CreateUserDrawer />   :
				<Popover
					isOpen={isOpen}
					initialFocusRef={firstFieldRef}
					onOpen={onOpen}
					onClose={onClose}
					placement="left"
					closeOnBlur={false}
				>
					<PopoverTrigger>
						<IconButton aria-label='button' size="sm" icon={<AddIcon />} />
					</PopoverTrigger>
					<PopoverContent p={5}>
						<PopoverArrow />
						<PopoverCloseButton />
						<Form firstFieldRef={firstFieldRef} onCancel={onClose} dispatch={dispatch}/>
					</PopoverContent>
				</Popover>}
		</Box>
	)
}
