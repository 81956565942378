/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text, Flex, Heading, VStack, useMediaQuery, Divider } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { useHistory } from 'react-router'
import Device from './Devices/Device'

import ConnectDevicePopup from './Sat/ConnectDevicePopup'

export default function Sat() {
	const [isMobile] = useMediaQuery('(max-width: 33em)')
	const [device, setDevice] = useState<any>()
	const history = useHistory()
	const btnRef = useRef()
	return (
		<Flex height='fit-content' minHeight='89vh' width='100vw' p={3}flexDirection='column'>
			<Heading pl={5} pt={5} fontSize='18px'>SAT</Heading>
			<VStack p={2} align='stretch' background='white' pt={5} mt={5} shadow='base' >
				<Flex flexDirection='row'  alignItems='center'>
					<Text m={3} fontSize='18' fontWeight='bold'>Connect to a new centrifuge</Text>
					<ConnectDevicePopup isMobile={isMobile} btnRef={btnRef} setDevice={setDevice}/>
				</Flex>
				{ device == undefined ? null
					: (
						<ul>
							<Device title={device.model} src={device.image} 
								subtitle={device.reference} voltage={device.voltage} 
								frequency={device.frequency} sn={device.sn}
								onClick={()=> history.push(`devices/${device.sn}`)}/>
							<Divider mt={3} mb={3}/>
						</ul>
					)
				}
			</VStack>

		</Flex>
	)
}
