/* eslint-disable react/no-children-prop */
import { Alert, AlertIcon, Button, Flex, Heading, Icon, Img, Input, InputGroup, InputLeftElement, InputRightElement, useMediaQuery, VStack } from '@chakra-ui/react'
import { FormEvent, useState } from 'react'
import logoEslogan from '../../logo-ortoalresa.png'
import logoSVG from '../../logoOA.svg'
import { useHistory } from 'react-router-dom'
import { EmailIcon, InfoIcon, LockIcon } from '@chakra-ui/icons'
import loginImage from '../../heroHome.jpeg'
import { setError, signup } from '../../redux/actions/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
export default function Main() {
	const [isMobile] = useMediaQuery('(max-width: 48em)')
	const history = useHistory()
	const dispatch = useDispatch()
	const { error } = useSelector((state: RootState) => state.auth)
	const [loading, setLoading] = useState(false)
	const [show, setShow] = useState(false)
	const handleClick = () => setShow(!show)
	const [formData, setFormData] = useState({
		username: '',
		email: '',
		password: '',
		repeat: ''
	})
	const { username, email, password, repeat } = formData
	const handleChange = (e: { target: { name: string; value: string } }) =>{
		setFormData({...formData,
			[e.target.name] : e.target.value
		})
	}
	const submitForm =(e: FormEvent) =>{
		e.preventDefault()
		if(username.trim() === '' || email.trim() === '' || password.trim() === '' || repeat.trim() === ''){
			return dispatch(setError('Please, complete all the fields'))
		}
		if(password !== repeat) {
			return dispatch(setError('Passwords don\'t match'))
		}
        
		setLoading(true)
        
		dispatch(signup({username,email,password}, ()=> setLoading(false)))
		if(error) {
			return dispatch(setError(error))
		}else{
			history.push('/')
		}
	}
    
	return (
        
		<Flex height={isMobile ? '100%' : '100vh'} alignItems='center' background='white' justifyContent={'center'} bgImage={loginImage} backgroundSize='cover' bgPos={-200} bgRepeat='no-repeat'>
			<Flex direction='row-reverse' justifyContent='revert' width='100%' height='full'>
				<Flex height='100%' overflowY='scroll' width={['100%','100%','40%']} maxW='800' minW='300'
					background='white' justifyContent='center' alignItems='center'>
           
					<VStack w='100%'>
						<Flex direction='column' maxW='400px' w='100%' justifyContent='center' p={8} pt={10} pb={10}>
							{isMobile ?
								<Img src={logoSVG}  w='3xl' p={8}/> : <Img src={logoEslogan} maxW={['200px','200px','250px','350px']} w='100%'
									position='absolute' left='0' bottom={32} bg='whiteAlpha.600'
									p={12}/>}
							<Heading mb={6}>Sign up</Heading>
							<InputGroup>
								<InputLeftElement children={<InfoIcon/>} />
								<Input placeholder='Name' 
									variant='filled' mb={3} 
									type='text'
									name='username'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
							</InputGroup>
							<InputGroup>
								<InputLeftElement children={<EmailIcon/>} />
								<Input placeholder='mail@mail.com' 
									autoComplete='email' 
									variant='filled' mb={3} 
									type='email' name='email' 
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
                    
							</InputGroup>
							<InputGroup>
								<InputLeftElement children={<LockIcon/>} />
								<Input placeholder='Password' 
									autoComplete='current-password' 
									variant='filled' mb={3} 
									type={show ? 'text' : 'password'}
									name='password'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
								<InputRightElement>
									<Icon onClick={handleClick} as={show ? AiFillEyeInvisible : AiFillEye}/>
								</InputRightElement>
							</InputGroup>
							<InputGroup>
								<InputLeftElement children={<LockIcon/>} />
								<Input placeholder='Repeat Password' 
									autoComplete='current-password' 
									variant='filled' mb={3} 
									type={show ? 'text' : 'password'}
									name='repeat'
									onChange={handleChange}
									borderRadius='0'
									bgColor='white'
									border='1px'
									borderColor='gray.200'/>
								<InputRightElement>
									<Icon onClick={handleClick} as={show ? AiFillEyeInvisible : AiFillEye}/>
								</InputRightElement>
							</InputGroup>
                
							<Button colorScheme='red' type='submit' onClick={submitForm} isLoading={loading} borderRadius='0'>Sign Up</Button>
							<Button fontSize='sm' variant='link' mt={3} onClick={() => history.push('/')}>Already have an account? Log in</Button>
						</Flex>
						{ error 
							? <Alert mb={3} rounded={8} status="error" maxW={['350px','350px','300px','400px']}>
								<AlertIcon />
								{error}
							</Alert> 
							: null }
					</VStack>
				</Flex>
			</Flex>
		</Flex>
	)
}
