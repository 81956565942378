/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ref,getDownloadURL,getStorage } from 'firebase/storage'

export default function UserAvatar({doc,setLoaded,isMobile}:any) {
	const [pfpUrl, setPfpUrl] = useState('initialState')
	const storage = getStorage()
	useEffect(() => {
		const getUrl = async () =>{
			const pfpRef = await ref(storage,doc.pfp)
			getDownloadURL(pfpRef).then(function(url){
				setPfpUrl(url)
			})
		}
		getUrl()
	}, [])
	return <Avatar p={0} name={doc.username} src={pfpUrl} onLoad={()=> setLoaded(true)} size={isMobile ? 'xs' : 'md'}/>
}
