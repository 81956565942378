/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Flex, Heading, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import {  Fragment, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Device from './Devices/Device'
import DevicePopup from './Devices/DevicePopup'

export default function Devices({devices}:any) {
	const [isMobile] = useMediaQuery('(max-width: 33em)')
	const history = useHistory()

	const btnRef = useRef()
  
	return (
		<Flex height='fit-content' minHeight='89vh' width='100vw' p={3} flexDirection='column' >
			<Heading pl={5} pt={5} fontSize='18px'>DEVICES</Heading>
			<VStack p={2} align='stretch' background='white' pt={5} m='auto' mt={5} width='50%' shadow='base' >
				<Flex flexDirection='row'  alignItems='center'>
					<Text m={3} fontSize='18' fontWeight='bold'>Register a new centrifuge</Text>
					<DevicePopup isMobile={isMobile} btnRef={btnRef}/>
				</Flex>
				{ devices !== [] ?
					(<ul>
						{devices.map((doc: { model: string, image: string, sn: string,
                reference:string,voltage:string,frequency:string}) => 
							<Fragment key={doc.sn}>
								<Device title={doc.model} src={doc.image} 
									subtitle={doc.reference} voltage={doc.voltage} 
									frequency={doc.frequency} sn={doc.sn}
									onClick={()=> history.push(`devices/${doc.sn}`)}/>
								<Divider mt={3} mb={3}/>
							</Fragment>
						) }
					</ul>) : null  
				}
			</VStack>
		</Flex>
	)
}