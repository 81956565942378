/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flex, Heading, Divider,Text, useMediaQuery, VStack } from '@chakra-ui/react'
//import { useSelector } from 'react-redux'
//import { RootState } from '../../redux/store'
import { useHistory } from 'react-router'
import { Fragment, useRef, useState, useEffect } from 'react'
import Device from './dashboard/content/Devices/Device'
import { db } from '../../firebase/config'
import CreateDevicePopup from './dashboard/content/Production/CreateDevicePopup'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { query, collection, where, onSnapshot } from '@firebase/firestore'

export default function Production() {
	const {  user } = useSelector((state: RootState) => state.auth)
	const history = useHistory()
	const [isMobile] = useMediaQuery('(max-width: 33em)')
	const [devices, setDevices] = useState<any>([])
	const btnRef = useRef()
    
	useEffect(() => {
		// firebase.firestore().collection('devices').where('user_id','array-contains', 'prod').onSnapshot(docSnapshot =>{
		// 	setDevices(docSnapshot.docs.map(doc => doc.data()))
		// }, err => {
		// 	console.log(`Encountered error: ${err}`)
		// })
		const q = query(collection(db, 'devices'), where('user_id', 'array-contains', 'prod'))
		onSnapshot(q, (querySnapshot) => {
			setDevices(querySnapshot.docs.map(doc => doc.data()))
		})
	}, [])
	return (
		<Flex height='89vh' width='100vw'  padding={[0,3]} borderRadius={8} flexDirection={['column','row']} >
			<Flex direction='column' w={'100%'} mb={[10,0]}>
				<Heading pl={5} pt={5} fontSize='18px'>HOME</Heading>
				<VStack p={2} align='stretch' background='white' pt={5} m='auto' mt={5} width='50%' shadow='base' >
					<Flex flexDirection='row'  alignItems='center'>
						<Text m={3} fontSize='18' fontWeight='bold'>Create a new centrifigue</Text>
						<CreateDevicePopup isMobile={isMobile} btnRef={btnRef}/>
					</Flex>
					{ devices !== [] ?
						(<ul>
							{devices.map((doc: { model: string, image: string, sn: string,reference:string,voltage:string,frequency:string,mac_add:string}) => 
								<Fragment key={doc.sn}>
									<Device title={doc.model + ' | ' + doc.sn + ' | ' + doc.mac_add} src={doc.image} 
										subtitle={doc.reference} voltage={doc.voltage} 
										frequency={doc.frequency} sn={doc.sn}
										onClick={()=> history.push(`production/${doc.sn}`)}
										deletable uid={user?.id}
									/>
									<Divider mt={3} mb={3}/>
								</Fragment>
							) }
						</ul>) : null  
					}
				</VStack>
                      
			</Flex>
            
		</Flex>
	)
}