/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AddIcon } from '@chakra-ui/icons'
import { Text, Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverCloseButton, ButtonGroup, Stack, FormControl, FormLabel, Input, Box, IconButton, useDisclosure, Select, InputGroup, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react'

import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { RootState } from '../../../../../redux/store'
import { SET_ERROR } from '../../../../../redux/types/types'
import { REGISTER_CENTRIFUGE } from '../../../../../urlConfig'
import DeviceDrawer from './DeviceDrawer'

const SerialInput = forwardRef((props:any, _ref) => {
	
	return (
		<FormControl>
			<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
			<InputGroup>
				<NumberInput min={210000}  max={219999} w='100%' name='sn' value={props.sn} onChange={props.handleChangeSn} >
					<NumberInputField defaultValue={210000} />
				</NumberInput>
				<Text fontSize='2xl'>
				&nbsp;/&nbsp;
				</Text>
				<NumberInput min={0} max={99}  maxW='30%' name='deviceNumber' value={props.deviceNumber} onChange={props.handleChangeNumber} >
					<NumberInputField />
					<NumberInputStepper >
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			</InputGroup>
		</FormControl>
	)
})
const TextInput = forwardRef((props:any, ref) => {
	return (
		<FormControl>
			<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
			<InputGroup>
				<Input ref={ref as any} name='mac1' {...props} onChange={props.handlechange} maxLength={2}/>
				<Input ref={ref as any} name='mac2' {...props} onChange={props.handlechange} maxLength={2}/>
				<Input ref={ref as any} name='mac3' {...props} onChange={props.handlechange} maxLength={2}/>
				<Input ref={ref as any} name='mac4' {...props} onChange={props.handlechange} maxLength={2}/>
				<Input ref={ref as any} name='mac5' {...props} onChange={props.handlechange} maxLength={2}/>
				<Input ref={ref as any} name='mac6' {...props} onChange={props.handlechange} maxLength={2}/>

			</InputGroup>
		</FormControl>
	)
})
const SelectInput = forwardRef((props:any, ref) => {
	return (
		<FormControl>
			<FormLabel htmlFor={props.id}>{props.label }</FormLabel>
			<Select ref={ref as any} name={props.id} {...props} onChange={props.handlechange}>
				<option value="">- Choose model -</option>
				<option value="Microcen 24">Microcen 24</option>
				<option value="Biocen 22">Biocen 22</option>
				<option value="Biocen 22 R">Biocen 22 R</option>
				<option value="Bioprocen 22 R">Bioprocen 22 R</option>
				<option value="Unicen 21">Unicen 21</option>
				<option value="Digicen 21">Digicen 21</option>
				<option value="Consul 22">Consul 22</option>

			</Select>
		</FormControl>
	)
})
const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 5000,
	timerProgressBar: false,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
})
 
const Form = ({ onCancel,dispatch, }:any) => {
	const {  user } = useSelector((state: RootState) => state.auth)

	const handleChangeNumber = (value:any) =>{
		setFormData({
			...formData,
			deviceNumber : value,
		})
	}
	const handleChangeSn = (value:any) =>{
		setFormData({
			...formData,
			sn : value,
		})
	}
	const [formData, setFormData] = useState({
		uid: user?.id,
		sn: 210000,
		mac1:'',
		mac2:'',
		mac3:'',
		mac4:'',
		mac5:'',
		mac6:'',
		deviceNumber: 0
	})
	const { sn, mac1,mac2,mac3,mac4,mac5,mac6, deviceNumber } = formData
	const handleSubmit = () =>{

		onCancel()
		try {
			fetch(REGISTER_CENTRIFUGE,{
				method:'POST',
				headers: {
					Accept: 'application/json'
				},
				mode: 'cors',
				body: JSON.stringify(formData)
			}).then(res => {
				res.json().then(data =>{
					res.status === 200 ? 
						Toast.fire({
							icon: 'success',
							title: data.msg
						}) : null
					res.status >= 400 ?
						Toast.fire({
							icon: 'error',
							title: data.msg
						}) : null
				})	
			})
		} catch (error:any) {
			dispatch({
				type: SET_ERROR,
				payload: error.message
			})
		}
	}
	const handleChange = (e: { target: { name: any; value: any } }) =>{
		setFormData({
			...formData,
			[e.target.name] : e.target.value
		})
	}

	return (
		<Stack spacing={4}>
			<SerialInput label="Serial Number"  id="sn" deviceNumber={deviceNumber} sn={sn}
				handleChangeNumber={handleChangeNumber} handlechange={handleChange}
				handleChangeSn={handleChangeSn}/>
			<TextInput label="Mac adress"  id="mac" handlechange={handleChange}/>
			<ButtonGroup d="flex" justifyContent="flex-end">
				<Button variant="outline" onClick={onCancel}>
            Cancel
				</Button>
				<Button isDisabled={ mac1.trim() === ''|| mac2.trim() === ''
				|| mac3.trim() === ''|| mac4.trim() === ''|| mac5.trim() === ''|| mac6.trim() === ''}
				colorScheme="red" 
				onClick={handleSubmit as any}
				>
            Save
				</Button>
			</ButtonGroup>
		</Stack>
	)
}
  
export default function DevicePopup({isMobile}:any) {
	const { onOpen, onClose, isOpen } = useDisclosure()
	const dispatch = useDispatch()
	const firstFieldRef = React.useRef(null)
  
	return (
		<Box ml='1em' mr='3em'>
			{ isMobile ? <DeviceDrawer />   :
				<Popover
					isOpen={isOpen}
					initialFocusRef={firstFieldRef}
					onOpen={onOpen}
					onClose={onClose}
					closeOnBlur={false}
				>
					<PopoverTrigger>
						<IconButton aria-label='button' size="sm" colorScheme='red' color='white' icon={<AddIcon />} />
					</PopoverTrigger>
					<PopoverContent p={5} maxWidth='400px' w='100%' >
						<PopoverArrow />
						<PopoverCloseButton />
						<Form firstFieldRef={firstFieldRef} onCancel={onClose} dispatch={dispatch}/>
					</PopoverContent>
				</Popover>}
		</Box>
	)
}
